<template>
  <!-- <html>
    <head>
      <title>Invoice MetalXperts LLP</title>
    </head>
    <body> -->
  <div class="body" id="sjInovice">
    <div
      class="row d-flex flex-row m-0 mt-14 g-12 justify-content-center align-items-center"
    >
      <div class="row bg-black">
        <div class="d-flex justify-content-start">
          <v-btn
            class="btn btn-warning btn-sm d-flex justify-content-between align-items-center bg-black"
            @click="printDoc"
            ><i class="bi bi-file-earmark-arrow-down-fill"></i>Print</v-btn
          >
          <v-btn
            class="btn btn-warning btn-sm d-flex justify-content-between align-items-center bg-black ms-3"
            @click="showList"
            ><i class="bi bi-file-earmark-arrow-down-fill"></i>Download</v-btn
          >
        </div>
      </div>
    </div>
    <div >
     
        <div class="pt-4">
         <table class= "border-0">
            <tr style="border: 1px solid ">
                <th colspan=18 style="border-0px"><h3>MetalXperts (India) LLP
           
                <label style="padding-left:64%">Sales Order Status - Summary</label></h3></th> </tr>
            <tr style="border: 1px solid #dddddd; background-color: #dddddd;">
                <th>Buyer </th>
                <th>Consignee</th>
                <th>Customer's PC / PO No.</th>

                <th>S.C. No. Date</th>
                <th>S.O. No. Date</th>
                <th>Mfg.</th>

                
                <th>Product Description</th>
                <th>Rate</th>

                <th>S.O. Qty.</th>
                <th>Short </th>
                <th>Excess </th>

                <th>Actual </th>
                <th>F.G. Qty.</th>
                <th>D.O.Qty.</th>

                <th>Dispatch</th>
                <th>Recived </th>
                <th>G.I.T</th>
                <th>Balanced</th>
            </tr>
            <tr style="border: 1px solid #dddddd; background-color: #dddddd;">
                <th colspan="8">02003 - Indian Standard Medium Weight Beam - ISMB</th>
                <th>1,865.270</th>
                <th>1,865.270</th>
                <th>0.000</th>
                <th>117.415</th>
                <th>1,982.685</th>
                <th>0.000</th>
                <th>542.747</th>
                <th>0.000</th>
                <th>0.000</th>
                <th>1,882.895</th>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr>
            <!-- <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td class="bold">PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr> -->
            <tr><td colspan="18">
                <span>
              <p class="">
                E & O.E <span class="alignright">Page No 1 of 1</span>
              </p>
            </span></td>
            </tr>
            <!-- <tr>
                <td>JSW STEEL LIMITED</td>
                <td>Dolvi, Raigarh </td>
                <td>SHREE UMIYA CUTTERS PRIVATE LIMITED</td>
                <td>23-01-2022</td>
                <td>PO-2122-00084 23-01-2022, 1</td>
                <td>JSW</td>
                <td>Dolvi</td>
                <td>
                Dimension: 1.80 x
                1250
                Grade: IS 2062 E 250
                A
                Edge: Trim
                </td>
                <td>64500</td>
                <td>0.000</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
                <td>64500</td>
            </tr> -->
         </table>
          <el-table-footer
            v-show="false"
            :data="summery"
            :ref="footerTableRef"
          ></el-table-footer>
        </div>
       
        <!-- <div class="bill_total_wrap">
          <div class="bill_sec p-10">
            <span>
              <p class="">
                E & O.E <span class="alignright">Page No 1 of 1</span>
              </p>
            </span>
          </div>
        </div> -->
      </div>
    </div>
  <!-- </div> -->
  <!-- </body>
  </html> -->
</template>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #121111;
  text-align: left;
  font-size: 10px;
  padding: 8px;
}
td {
  border: 1px solid #121111;
  text-align: left;
  padding: 8px;
  font-size: 10px;
}


</style>

<style type="text/css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;400;900&display=swap");

:root {
  --primary: #0000ff;
  --secondary: #3d3d3d;
  --white: #fff;
  --Black: #000;
}

* {
  margin: 0;
  padding: 0;
  /*box-sizing: border-box;
  font-family: 'Lato', sans-serif;*/
}


.bold {
  font-weight: bold;
}


table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  text-align: left;
  padding: 4px;
}

.alignright {
  float: right;
}


</style>
